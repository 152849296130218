import React from 'react'
import { v4 } from 'uuid'
import { Stack, Flex, Button, Box } from '@chakra-ui/react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { format } from 'date-fns'

import LandingPage, {
  Header,
  Gallery,
  Features,
  Faqs,
  Video,
  Testimonies,
} from '../components/landingPage'

import { H1, P, Image, WhatsappButton } from '../ui'
import FloatingBook from '../components/floatingBook'

import logo from '../images/logo-white.png'

function LandingJetsPage({ data }) {
  const { t } = useTranslation()

  return (
    <LandingPage disableWhatsapp title={t('landing:jets.title')}>
      <Header
        image={
          <Image
            fluid={data.hero.childImageSharp.fluid}
            layout="background"
            style={{ opacity: 0.45 }}
          />
        }
      >
        <Stack
          spacing={4}
          maxWidth="520px"
          alignSelf={{ base: 'center', md: 'flex-start' }}
          justifyContent="center"
        >
          <Flex
            alignItems="flex-start"
            justifyContent="center"
            width="100%"
            maxWidth="200px"
            height="auto"
            marginX="auto"
            opacity="0.85"
          >
            <img style={{ width: '100%' }} src={logo} />
          </Flex>
          <H1 color="white" fontSize="5xl" textAlign="center">
            {t('landing:jets.hero.title')}
          </H1>
          <P color="white" textAlign="center" fontSize="lg">
            {t('landing:jets.hero.desc')}
          </P>
          <Button
            as="a"
            href={`https://api.whatsapp.com/send?phone=5219983497125&text=${t(
              'landing:jets.whatsappText'
            )}`}
            size="lg"
            colorScheme="primary"
          >
            {t('landing:jets.button')}
          </Button>
          <P textAlign="center" color="white" lineHeight="shorter" fontSize="sm">
            {t('landing:jets.hero.terms')}
          </P>
        </Stack>
      </Header>

      <Video
        title={t('landing:video.title')}
        videoUrl="https://www.youtube.com/watch?v=J9yU6ySp9qw"
      />

      <Features
        title={t('landing:jets.why.title')}
        list={[
          {
            image: <Image fixed={data.why1.childImageSharp.fixed} layout="background" />,
            title: t('landing:jets.why.features.one.title'),
            desc: t('landing:jets.why.features.one.desc'),
          },
          {
            image: <Image fixed={data.why2.childImageSharp.fixed} layout="background" />,
            title: t('landing:jets.why.features.two.title'),
            desc: t('landing:jets.why.features.two.desc'),
          },
          {
            image: <Image fixed={data.why3.childImageSharp.fixed} layout="background" />,
            title: t('landing:jets.why.features.three.title'),
            desc: t('landing:jets.why.features.three.desc'),
          },
        ]}
      />

      <Testimonies
        title={t('landing:testimonies.title')}
        list={t('landing:testimonies.items', { returnObjects: true })}
        stars={5}
      />

      <Gallery
        title={t('landing:gallery.title')}
        viewPhotos={t('landing:gallery.viewPhotos')}
        images={data.gallery.edges.map((image) => (
          <Image key={v4()} fixed={image.node.childImageSharp.fixed} layout="background" />
        ))}
      />

      <Faqs
        title={t('landing:jets.faqs.title')}
        list={t('landing:jets.faqs.items', { returnObjects: true })}
      />

      <Box height="100px" />

      <FloatingBook
        title={t('landing:jets.floatingBookNow.title')}
        desc={t('landing:jets.floatingBookNow.desc')}
        button={
          <Button colorScheme="primary" flexShrink="0" size="md">
            {t('landing:jets.button')}
          </Button>
        }
      />

      <WhatsappButton
        bottom="75px"
        href={`https://api.whatsapp.com/send?phone=5219983497125&text=${t(
          'landing:jets.whatsappText'
        )}`}
      />
    </LandingPage>
  )
}

export const query = graphql`
  query {
    hero: file(relativePath: { eq: "landing/jets/bg.jpeg" }) {
      ...bannerSectionImage
    }

    gallery: allFile(
      filter: { relativeDirectory: { eq: "landing/jets/gallery" } }
      sort: { fields: name }
    ) {
      ...multipleFixedImage
    }

    why1: file(relativePath: { eq: "landing/jets/experience.jpg" }) {
      ...fixedImage
    }

    why2: file(relativePath: { eq: "landing/jets/concierge.jpg" }) {
      ...fixedImage
    }

    why3: file(relativePath: { eq: "landing/jets/exclusive.jpg" }) {
      ...fixedImage
    }
  }
`

export default LandingJetsPage
